import {useEffect, useState} from 'react';
import {getAxios} from '../services/api';
import {useHistory} from 'react-router-dom';
import {Button, message, Space} from 'antd';
import qs from 'qs';
import {getInnerTextOfHtml, randomizeArrayOrder} from '../helper';
import {Helmet} from 'react-helmet';

export default function NotesPage() {
  const [notes, setNotes] = useState([]);
  const [results, setResults] = useState([]);
  const history = useHistory();

  async function loadNotes() {
    const userId = JSON.parse(localStorage.getItem('user')).id;

    const query = qs.stringify({
      populate: ['question'],
      sort: 'createdAt:desc',
      filters: {
        user: {
          id: {
            $eq: userId,
          },
        },
      },
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/notes?${query}`);
    setNotes(res.data.data);
  }

  useEffect(() => {
    (async () => {
      await loadNotes();
    })();
  }, []);

  async function viewNoteDetail(note) {
    const ids = notes.map(item => {
      try {
        return item.attributes.question.data.id;
      } catch (e) {
        return null;
      }
    }).filter(item => !!item);

    console.log('ids', ids);

    const query = qs.stringify({
      populate: ['options'],
      pagination: {
        pageSize: 1000,
      },
      filters: {
        id: {
          $in: ids,
        },
      },
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/questions?${query}`);
    const items = randomizeArrayOrder(res.data.data).map(item => {
      return {
        ...item,
        validIds: item.attributes.options.filter(item => item.isValid).map(item => item.id),
        selectedIds: [],
      };
    });

    let current = 0;
    items.forEach((item, index) => {
      if (item.id === note.attributes.question.data.id) {
        current = index;
      }
    })

    const DEFAULT_EXAM = {
      current,
      validIds: [],
      selectedIds: [],
    };

    localStorage.setItem('exam', JSON.stringify(DEFAULT_EXAM));
    localStorage.setItem('questions', JSON.stringify(items));
    localStorage.removeItem('historyMode');
    history.push('/current-exam');
  }

  async function deleteNoteDetail(note) {
    await getAxios().delete(`/api/notes/${note.id}`);
    message.success('Deleted note successfully');

    await loadNotes();
  }

  return (
      <div>

        <Helmet>
          <title>My notes - IT Exam</title>
        </Helmet>

        <h3>Your saved notes</h3>
        <table className={'table table-sm table-striped'}>
          <thead className={'thead-dark'}>
          <tr>
            <th>No</th>
            <th>Content</th>
            <th>Question</th>
            <th style={{
              textAlign: 'right',
            }}>Action
            </th>
          </tr>
          </thead>
          <tbody>
          {notes.map((note, index) => {
            return (
                <tr key={note.id}>
                  <td>{index + 1}</td>
                  <td>{getInnerTextOfHtml(note.attributes.content)}</td>
                  <td>
                    <div className={'threeRows'} dangerouslySetInnerHTML={{
                      __html: note.attributes.question.data.attributes.content
                    }}></div>
                  </td>
                  <td style={{
                    textAlign: 'right',
                  }}>
                    <Space>
                      <Button type={'default'}
                              onClick={() => {
                                viewNoteDetail(note);
                              }}
                      >
                        <a>View</a>
                      </Button>
                      <Button danger
                              onClick={() => {
                                deleteNoteDetail(note);
                              }}
                      >
                        <a>Delete</a>
                      </Button>
                    </Space>
                  </td>
                </tr>
            );
          })}
          <tr></tr>
          </tbody>
        </table>
      </div>
  );
}

import axios from 'axios';
import CONFIG from '../config';

export function getAxios() {
  if (!window['custom_axios']) {
    window['custom_axios'] = axios.create({
      baseURL: `${CONFIG.apiEndpoint}`,
    });

    window['custom_axios'].interceptors.request.use(function(config) {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    window['custom_axios'].interceptors.response.use(response => response, error => {
      if (error.response.status === 403) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('questions');
        localStorage.removeItem('exam');

        window.location.href = '/auth/init';
      }
    });
  }

  return window['custom_axios'];
}

import showdown from 'showdown';

export function randomizeArrayOrder(arr) {
  // Create a copy of the original array to avoid modifying it directly
  const newArray = arr.slice();

  for (let i = newArray.length - 1; i > 0; i--) {
    // Generate a random index between 0 and i (inclusive)
    const randomIndex = Math.floor(Math.random() * (i + 1));

    // Swap elements at randomIndex and i
    const temp = newArray[i];
    newArray[i] = newArray[randomIndex];
    newArray[randomIndex] = temp;
  }

  return newArray;
}

export function convertToHtmlToOpenLinkInNewTab(htmlText) {
  const parser = new DOMParser();

  let content = parser.parseFromString(htmlText, 'text/html');
  const anchors = content.getElementsByTagName('a');

  Array.from(anchors).forEach(a => {
    a.setAttribute('target', '_blank');
  });

  return content.body.innerHTML;
}

export function convertMarkdownToHtml(markdownText) {
  const converter = new showdown.Converter();
  const htmlText = converter.makeHtml(markdownText);

  const parser = new DOMParser();

  let content = parser.parseFromString(htmlText, 'text/html');
  const anchors = content.getElementsByTagName('a');

  Array.from(anchors).forEach(a => {
    a.setAttribute('target', '_blank');
  });

  return content.body.innerHTML;
}

export function getInnerTextOfHtml(htmlText) {
  const parser = new DOMParser();
  let content = parser.parseFromString(htmlText, 'text/html');

  return content.body.innerText;
}

import {useEffect, useState} from 'react';
import {getAxios} from '../services/api';
import qs from 'qs';
import {convertMarkdownToHtml, getInnerTextOfHtml, randomizeArrayOrder} from '../helper';
import {Helmet} from 'react-helmet';

export default function HomePage() {
  const [announcement, setAnnouncement] = useState('');
  const [notes, setNotes] = useState([]);

  async function loadNotes() {
    const query = qs.stringify({
      sort: 'createdAt:desc',
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/vouchers?${query}`);
    setNotes(res.data.data);
  }

  async function loadAnnouncement() {
    const res = await getAxios().get(`/api/announcement`);
    setAnnouncement(res.data.data)
  }

  useEffect(() => {
    (async () => {
      await loadNotes();
      await loadAnnouncement();
    })();
  }, []);

  return (
      <div>

        <Helmet>
          <title>Home - IT Exam</title>
        </Helmet>


        <h3>System Announcement</h3>
        <div className="announcement breakWord" dangerouslySetInnerHTML={{
          __html: announcement ? convertMarkdownToHtml(announcement.attributes.content) : ''
        }}>

        </div>

        <br/>

        <h3>Vouchers news</h3>
        <table className={'table table-sm table-striped'}>
          <thead className={'thead-dark'}>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Content</th>
          </tr>
          </thead>
          <tbody>
          {notes.map((note, index) => {
            return (
                <tr key={note.id}>
                  <td>{index + 1}</td>
                  <td>{note.attributes.name}</td>
                  <td>
                    <div className={'voucher-note'} dangerouslySetInnerHTML={{
                      __html: convertMarkdownToHtml(note.attributes.content)
                    }}></div>
                  </td>
                </tr>
            );
          })}
          <tr></tr>
          </tbody>
        </table>
      </div>
  );
}

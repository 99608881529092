import {useEffect, useState} from 'react';
import {getAxios} from '../services/api';
import {useHistory} from 'react-router-dom';
import {Button, message, Space} from 'antd';
import qs from 'qs';
import {randomizeArrayOrder} from '../helper';
import {Helmet} from 'react-helmet';

export default function HistoriesPage() {
  const [results, setResults] = useState([]);
  const history = useHistory();

  async function loadItems() {
    const userId = JSON.parse(localStorage.getItem('user')).id;

    const query = qs.stringify({
      filters: {
        user: {
          id: {
            $eq: userId,
          },
        },
      },
      sort: 'createdAt:desc'
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/results?${query}`);
    setResults(res.data.data);
  }

  useEffect(() => {
    (async () => {
      await loadItems();
    })();
  }, []);

  function viewHistory(item) {
    localStorage.setItem('questions', JSON.stringify(item.attributes.questions));
    localStorage.setItem('historyMode', '1');
    localStorage.removeItem('exam');
    history.push('/current-exam');
  }

  async function deleteResultDetail(result) {
    await getAxios().delete(`/api/results/${result.id}`);
    message.success('Deleted result successfully');

    await loadItems();
  }


  return (
      <div>

        <Helmet>
          <title>My test histories - IT Exam</title>
        </Helmet>

        <h3>My test histories</h3>
        <table className={'table table-sm table-striped'}>
          <thead className={'thead-dark'}>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Results</th>
            <th style={{
              textAlign: 'right',
            }}>Action
            </th>
          </tr>
          </thead>
          <tbody>
          {results.map((item, index) => {
            const correctPercent = item.attributes.validCounter / item.attributes.questions.length;
            return (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.attributes.name}</td>
                  <td>{item.attributes.validCounter} / {item.attributes.questions.length} ({correctPercent >= 0.75 ? 'Pass' : 'Fail'})</td>
                  <td style={{
                    textAlign: 'right',
                  }}>
                    <Space>
                      <Button type={'default'}
                              onClick={() => {
                                viewHistory(item);
                              }}
                      >
                        <a>View</a>
                      </Button>
                      <Button danger
                              onClick={() => {
                                deleteResultDetail(item);
                              }}
                      >
                        <a>Delete</a>
                      </Button>
                    </Space>
                  </td>
                </tr>
            );
          })}
          <tr></tr>
          </tbody>
        </table>
      </div>
  );
}

import './App.css';

import {BrowserRouter as Router, Redirect, Route, Switch, useHistory} from 'react-router-dom';

import AuthPage from './pages/AuthPage';
import AuthCallbackPage from './pages/AuthCallbackPage';
import CoursesPage from './pages/CoursesPage';
import QuestionsPage from './pages/QuestionsPage';
import {useEffect} from 'react';
import {AuthLayout} from './components/AuthLayout';
import Exam from './pages/Exam';
import NotesPage from './pages/NotesPage';
import HistoriesPage from './pages/HistoriesPage';
import MyFalseResponsesPage from './pages/MyFalseResponsesPage';
import TopFalseResponsesPage from './pages/TopFalseResponses';
import ContactPage from './pages/ContactPage';
import {ConfigProvider, theme} from 'antd';
import DashboardPage from './pages/HomePage';
import CollectionsPage from './pages/CollectionsPage';
import SharedCollectionsPage from './pages/SharedCollectionsPage';

function App() {
  const history = useHistory();
  useEffect(() => {
    console.log('DEBUG history', history);
  }, [history?.location]);

  return (
      <ConfigProvider
          theme={{
            components: {
              Button: {
                colorPrimary: '#000',
                algorithm: true, // Enable algorithm
              },
              Checkbox: {
                colorPrimary: '#000',
                algorithm: true, // Enable algorithm
              }
            },
            // algorithm: theme.darkAlgorithm
          }}>
        <Router>
          <Switch>
            <Route exact path="/">
              <AuthLayout authRequired={false}>
                <DashboardPage/>
              </AuthLayout>
            </Route>

            <Route path="/courses">
              <AuthLayout>
                <CoursesPage/>
              </AuthLayout>
            </Route>

            <Route path="/notes">
              <AuthLayout>
                <NotesPage/>
              </AuthLayout>
            </Route>

            <Route path="/my-collections">
              <AuthLayout>
                <CollectionsPage/>
              </AuthLayout>
            </Route>

            <Route path="/shared-collections">
              <AuthLayout>
                <SharedCollectionsPage/>
              </AuthLayout>
            </Route>

            <Route path="/contact-us">
              <AuthLayout>
                <ContactPage />
              </AuthLayout>
            </Route>

            <Route path="/my-wrong-answers">
              <AuthLayout>
                <MyFalseResponsesPage/>
              </AuthLayout>
            </Route>

            <Route path="/top-wrong-answers">
              <AuthLayout>
                <TopFalseResponsesPage/>
              </AuthLayout>
            </Route>

            <Route path="/histories">
              <AuthLayout>
                <HistoriesPage/>
              </AuthLayout>
            </Route>

            <Route path="/questions">
              <AuthLayout>
                <QuestionsPage/>
              </AuthLayout>
            </Route>

            <Route path="/current-exam">
              <AuthLayout>
                <Exam/>
              </AuthLayout>
            </Route>

            <Redirect exact from={'/auth/logout'} to="/auth/init"/>

            <Route path="/auth/callback">
              <AuthCallbackPage/>
            </Route>

            <Redirect exact from={'/auth'} to="/auth/init"/>
            <Route path="/auth/init">
              <AuthPage/>
            </Route>

            <Redirect to="/" />
          </Switch>
        </Router>
      </ConfigProvider>
  );
}

export default App;

import CONFIG from '../config';
import {Button} from 'antd';
import {useEffect} from 'react';
import {getAxios} from '../services/api';
import {Helmet} from 'react-helmet';

export default function AuthPage() {
  function loginWithRedirect() {
    location.href = `${CONFIG.apiEndpoint}/api/connect/google?callback=${location.origin}/auth/callback`;
  }

  useEffect(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('historyMode');
    localStorage.removeItem('questions');
    localStorage.removeItem('exam');
  }, [])

  async function useDemoAccount() {
    const res = await getAxios().post('/api/auth/local', {
      'identifier': 'guest',
      'password': 'guest@12345',
    });

    localStorage.setItem('token', res.data.jwt);
    localStorage.setItem('user', JSON.stringify(res.data.user));

    const redirect = localStorage.getItem('redirectUrl');
    if (redirect) {
      localStorage.removeItem('redirectUrl')
    }
    window.location.href = redirect ? redirect : '/';
  }

  return (
      <div>
        <Helmet>
          <title>Login - IT Exam</title>
        </Helmet>


        <center>
          <br/><br/><br/>
          <Button
              style={{minWidth: '200px', maxWidth: '100%'}}
              onClick={() => loginWithRedirect()}
              type="primary">
            Login with Google
          </Button>

          <br/>or<br/>

          <Button
              style={{minWidth: '200px', maxWidth: '100%'}}
              onClick={() => useDemoAccount()}
              type="default">
            Login as guest user
          </Button>
          <br/><br/>
          <p>Note: If you use guest account, your quiz histories are shared with other guest users.</p>
        </center>
      </div>
  );
}

import {Fragment, useEffect, useRef, useState} from 'react';
import {Button, message} from 'antd';
import {ImCheckboxChecked, ImCheckboxUnchecked} from 'react-icons/im';
import {MdRadioButtonChecked, MdRadioButtonUnchecked} from 'react-icons/md';
import {getAxios} from '../services/api';
import qs from 'qs';
import {FiArrowLeft, FiArrowRight} from 'react-icons/fi';
import moment from 'moment';
import {convertToHtmlToOpenLinkInNewTab} from '../helper';
import {Helmet} from 'react-helmet';
import {useSwipeable} from 'react-swipeable';

export default function Exam() {
  const [questions, setQuestions] = useState([]);
  const [exam, setExam] = useState(null);
  const [isShowResults, setIsShowResults] = useState(false);
  const [collections, setCollections] = useState([]);
  const [note, setNote] = useState('');
  const [noteId, setNoteId] = useState(null);
  const [isSingle, setIsSingle] = useState(false);
  const [showPaging, setShowPaging] = useState(true);
  const [showHotKeysGuide, setShowHotKeysGuide] = useState(true);
  const currentIsSingle = useRef(false);
  const currentIndex = useRef(0);
  const showResultsRef = useRef(false);
  const noteRef = useRef('');
  const noteIdRef = useRef(null);
  const onFocusRef = useRef(false);
  const changedNoteRef = useRef(false);

  const swiperHandlers = useSwipeable({
    onSwiped: () => console.log('swiped'),
    onSwipedLeft: () => nextQuestion(),
    onSwipedRight: () => previousQuestion(),
  });

  async function loadCollections() {
    const userId = JSON.parse(localStorage.getItem('user')).id;

    const query = qs.stringify({
      populate: ['questions'],
      sort: 'createdAt:desc',
      filters: {
        owner: {
          id: {
            $eq: userId,
          },
        },
      },
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/collections?${query}`);
    setCollections(res.data.data);
  }

  useEffect(() => {
    const mappingQuestionIdToCollection = {};
    collections.forEach(item => {
      item.attributes.questions?.data.forEach(question => {
        mappingQuestionIdToCollection[question.id] = item.id;
      });
    });

    console.log('DEBUG mappingQuestionIdToCollection', mappingQuestionIdToCollection);
  }, [collections]);

  useEffect(() => {
    loadCollections();
  }, []);

  useEffect(() => {
    noteRef.current = note;
  }, [note]);

  useEffect(() => {
    noteIdRef.current = noteId;
  }, [noteId]);

  useEffect(() => {
    const historyMode = localStorage.getItem('historyMode');
    if (historyMode) {
      // localStorage.removeItem('historyMode');
      setIsShowResults(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShowHotKeysGuide(false);
    }, 30 * 1000);
  }, []);

  useEffect(() => {
    let ref;
    if (exam) {
      ref = setTimeout(() => {
        (async () => {
          try {
            const questionsState = JSON.parse(
                localStorage.getItem('questions') || '[]');
            const userId = JSON.parse(localStorage.getItem('user')).id;
            const query = qs.stringify({
              filters: {
                user: {
                  id: {
                    $eq: userId,
                  },
                },
                question: {
                  id: {
                    $eq: questionsState[currentIndex.current].id,
                  },
                },
              },
            }, {
              encodeValuesOnly: true,
            });

            const res = await getAxios().get(`/api/notes?${query}`);
            if (!res || !res.data.data.length) {
              setNote('');
              setNoteId(null);
            } else {
              setNote(res.data.data[0].attributes.content);
              setNoteId(res.data.data[0].id);
            }
          } catch (e) {
            console.log('debug e', e);
          }
        })();
      }, 250);
    }

    return function() {
      clearTimeout(ref);
    };
  }, [exam?.current]);

  useEffect(() => {
    if (exam) {
      currentIndex.current = exam.current;
    }
  }, [exam]);

  useEffect(() => {
    showResultsRef.current = isShowResults;
  }, [isShowResults]);

  useEffect(() => {
    currentIsSingle.current = isSingle;
  }, [isSingle]);

  useEffect(() => {
    if (!questions || !exam) {
      return;
    }

    const items = questions[exam?.current]?.attributes.options.filter(
        item => item.isValid);
    setIsSingle(items && items.length === 1);
  }, [questions, exam]);

  useEffect(() => {
    const ref = async (e) => {
      if (onFocusRef.current) {
        return;
      }

      switch (e.code) {
        case 'ArrowLeft':
          await previousQuestion();
          break;
        case 'ArrowUp':
        case 'ArrowDown':
          await markToReview();
          break;
        case 'ArrowRight':
          await nextQuestion();
          break;
        case 'Digit1':
        case 'Digit2':
        case 'Digit3':
        case 'Digit4':
        case 'Digit5':
        case 'Digit6':
          const optionIndex = e.code.toLowerCase().split('digit')[1] - 1;
          await handleNumber(optionIndex);
          break;
      }
    };

    window.addEventListener('keyup', ref);

    return function() {
      window.removeEventListener('keyup', ref);
    };
  }, []);

  useEffect(() => {
    const questionsState = JSON.parse(
        localStorage.getItem('questions') || '[]');

    const DEFAULT_EXAM = {
      current: 0,
      validIds: [],
      selectedIds: [],
    };

    let examState;
    try {
      const localExam = JSON.parse(localStorage.getItem('exam'));
      if (localExam && localExam.hasOwnProperty('current')) {
        examState = localExam;
      } else {
        examState = DEFAULT_EXAM;
      }
    } catch (e) {
      examState = DEFAULT_EXAM;
    }

    setQuestions(questionsState);
    // setExam(examState);

    setExam({
      ...examState,
      selectedIds: questionsState[examState.current]?.selectedIds || [],
      validIds: questionsState[examState.current]?.validIds || [],
    });
  }, []);

  async function saveNote() {
    const userId = JSON.parse(localStorage.getItem('user')).id;
    const questionsState = JSON.parse(
        localStorage.getItem('questions') || '[]');

    // alert('save node');
    (async () => {
      try {

        // alert('save async');
        if (!noteId) {
          // alert('not id');
          console.log('DEBUG', {
            questionsState,
            currentIndex,
          });
          const afterSave = await getAxios().post(`/api/notes`, {
            data: {
              user: {
                connect: [userId],
              },
              question: {
                connect: [questionsState[currentIndex.current].id],
              },
              content: note,
            },
          });
          setNoteId(afterSave.data.data.id);
          message.success('Created note successfully');

        } else {
          await getAxios().put(`/api/notes/${noteId}`, {
            data: {
              content: note,
            },
          });
          message.success('Updated note successfully');

        }
      } catch (e) {
        console.log('e', e);
        // const res = await getAxios().post(`/api/notes?${query}`);
      }
    })();
  }

  async function deleteNote() {
    (async () => {
      await getAxios().delete(`/api/notes/${noteId}`, {
        data: {
          content: note,
        },
      });

      message.success('Deleted note successfully');

      setNote('');
      setNoteId(null);
    })();
  }

  function handleClickOption(id) {
    if (showResultsRef.current) {
      return;
    }

    const localExam = JSON.parse(localStorage.getItem('exam'));
    const localQuestions = JSON.parse(localStorage.getItem('questions'));

    const isChecked = localExam.selectedIds.includes(id);

    let newSelectedIds;
    if (!isChecked) {
      if (currentIsSingle.current) {
        newSelectedIds = [id];
      } else {
        newSelectedIds = [
          ...localExam.selectedIds,
          id,
        ];
      }
    } else {
      newSelectedIds = localExam.selectedIds.filter(item => item !== id);
    }

    localQuestions[currentIndex.current].selectedIds = newSelectedIds;
    setQuestions(localQuestions);

    setExam({
      ...localExam,
      selectedIds: newSelectedIds,
    });
  }

  useEffect(() => {
    if (exam) {
      localStorage.setItem('exam', JSON.stringify(exam));
      questions[exam.current].selectedIds = exam.selectedIds;
      setQuestions(JSON.parse(JSON.stringify(questions)));
    }
  }, [JSON.stringify(exam)]);

  useEffect(() => {
    if (questions && questions.length) {
      localStorage.setItem('questions', JSON.stringify(questions));
    }
  }, [questions]);

  async function changeQuestion(index) {
    console.log('DEBUG changeQuestion', {
      noteRef: noteRef.current,
      noteIdRef: noteIdRef.current,
    });

    const userId = JSON.parse(localStorage.getItem('user')).id;
    const localExam = JSON.parse(localStorage.getItem('exam'));
    const localQuestions = JSON.parse(localStorage.getItem('questions'));

    if (noteRef.current && changedNoteRef.current) {
      if (!noteIdRef.current) {
        await getAxios().post(`/api/notes`, {
          data: {
            user: {
              connect: [userId],
            },
            question: {
              connect: [localQuestions[currentIndex.current].id],
            },
            content: noteRef.current,
          },
        });
      } else {
        await getAxios().put(`/api/notes/${noteIdRef.current}`, {
          data: {
            content: noteRef.current,
          },
        });
      }
      message.success('Saved note automatically');
    }

    setExam({
      ...localExam,
      current: index,
      selectedIds: localQuestions[index].selectedIds,
      validIds: localQuestions[index].validIds,
    });

    changedNoteRef.current = false;
  }

  function markToReview() {
    if (showResultsRef.current) {
      return;
    }

    const localQuestions = JSON.parse(localStorage.getItem('questions'));
    localQuestions[currentIndex.current].flag = !localQuestions[currentIndex.current].flag;
    setQuestions(JSON.parse(JSON.stringify(localQuestions)));
  }

  async function previousQuestion() {
    if (currentIndex.current === 0) {
      return;
    }
    const newIndex = currentIndex.current - 1;
    await changeQuestion(newIndex);
  }

  async function nextQuestion() {
    const localQuestions = JSON.parse(localStorage.getItem('questions'));
    if (currentIndex.current >= localQuestions.length - 1) {
      return;
    }

    const newIndex = currentIndex.current + 1;
    await changeQuestion(newIndex);
  }

  function handleNumber(optionIndex) {
    if (showResultsRef.current) {
      return;
    }

    try {
      const index = optionIndex;
      const localQuestions = JSON.parse(localStorage.getItem('questions'));
      const optionId = localQuestions[currentIndex.current].attributes.options[index].id;
      handleClickOption(optionId);
    } catch (e) {
      console.log(e);
      // No handle
    }
  }

  async function showResults() {
    const name = prompt('Name of this test', 'Test exam at ' + moment().format('DD-MM-YYYY hh:mm:ss').valueOf());
    if (!name) {
      return;
    }

    try {
      const userId = JSON.parse(localStorage.getItem('user')).id;
      const questionsState = JSON.parse(
          localStorage.getItem('questions') || '[]');

      const validItems = questionsState.filter(item => {
        return item.selectedIds.sort().toString() === item.validIds.sort().toString();
      });

      const mappingIdToSelectedIds = {};

      const invalidIds = questionsState.filter(item => {
        return item.selectedIds.length && item.selectedIds.sort().toString() !== item.validIds.sort().toString();
      }).map(item => {
        mappingIdToSelectedIds[item.id] = item.selectedIds;
        return item.id;
      });

      console.log('DEBUG invalidIds', invalidIds);

      for (let i = 0; i < invalidIds.length; i++) {
        try {
          await getAxios().post(`/api/false-responses`, {
            data: {
              validCounter: validItems.length,
              user: {
                connect: [userId],
              },
              selectedIds: mappingIdToSelectedIds[invalidIds[i]],
              question: {
                connect: [invalidIds[i]],
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      }

      await getAxios().post(`/api/results`, {
        data: {
          validCounter: validItems.length,
          user: {
            connect: [userId],
          },
          name,
          questions: questionsState,
        },
      });
    } catch (e) {
      console.log('showResults error', e);
    }

    console.log('DEBUG name', name);
    localStorage.setItem('historyMode', 'true');
    const localQuestions = JSON.parse(localStorage.getItem('questions'));
    console.log('DEBUG localQuestions', localQuestions);
    setIsShowResults(!isShowResults);
  }

  async function handleClickCollection(collection) {
    const questionsState = JSON.parse(
        localStorage.getItem('questions') || '[]');
    console.log('DEBUG handleClickCollection', {
      collection,
      currentQuestion: questionsState[currentIndex.current].id
    });

    try {
      let isExist = false;
      collection.attributes.questions.data.forEach(item => {
        if (item.id === questionsState[currentIndex.current].id) {
          isExist = true;
        }
      });
      await getAxios().put(`/api/collections/${collection.id}`, {
        data: {
          questions: {
            [isExist ? 'disconnect' : 'connect']: [questionsState[currentIndex.current].id],
          },
        }
      });

      message.success(isExist ? 'Removed question from collection' : 'Added question to collection');
      loadCollections();
    } catch (e) {
      console.error(e);
      message.error('Failed to add/remove question to collection');
    }
  }

  return (
      <div>
        <div className={'exam-page-wrapper'}>

          <Helmet>
            <title>Current exam - IT Exam</title>
          </Helmet>

          <div className="question-area" {...swiperHandlers}>
            {!!exam && !!questions && !!questions.length && (
                <div>
                  <div className="question-heading">
                    <div className="left btn-back-question">
                      {exam.current !== 0 && (
                          <div
                              onClick={previousQuestion}>
                            <FiArrowLeft size={20} color={'white'}/>
                          </div>
                      )}
                    </div>
                    <div className="central">
                      <center>{exam.current + 1}/{questions.length}</center>
                    </div>
                    <div className="right btn-next-question">
                      {(exam.current !== questions.length - 1) && (
                          <div
                              onClick={nextQuestion}>
                            <FiArrowRight size={20} color={'white'}/>
                          </div>
                      )}
                    </div>
                  </div>
                  <div className={'question-wrapper'}>
                    <div className="left-content">
                      <div className="questionTitle" dangerouslySetInnerHTML={{
                        __html: questions[exam?.current].attributes.content,
                      }}>
                      </div>

                      <div className="options">
                        {questions[exam?.current].attributes.options.map(item => {
                          return (
                              <div className={`option ${isShowResults && item.isValid && 'valid'}`} onClick={() => {
                                handleClickOption(item.id);
                              }}>
                                <div className="indicator">
                                  {isSingle &&
                                      !exam.selectedIds.includes(item.id) &&
                                      <MdRadioButtonUnchecked size={15}/>}
                                  {isSingle &&
                                      exam.selectedIds.includes(item.id) &&
                                      <MdRadioButtonChecked size={15}/>}

                                  {!isSingle &&
                                      !exam.selectedIds.includes(item.id) &&
                                      <ImCheckboxUnchecked size={15}/>}
                                  {!isSingle &&
                                      exam.selectedIds.includes(item.id) &&
                                      <ImCheckboxChecked size={15}/>}
                                </div>
                                <div className="content"
                                     dangerouslySetInnerHTML={{
                                       __html: item.content,
                                     }}>

                                </div>
                              </div>
                          );
                        })}
                      </div>

                      <div className="collection-area" style={{
                        width: '260px',
                        display: 'block',
                      }}>
                        My collection
                        <br/>
                        {!!collections && !!collections.length && (
                            <div className={'listCollections'} style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '5px',
                              marginTop: '5px',
                              cursor: 'pointer',
                            }}>
                              {collections.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        style={{
                                          padding: '10px 15px',
                                          backgroundColor: item.attributes.questions.data.map(item => item.id).includes(questions[exam.current].id) ? 'green' : 'white',
                                          width: '100%',
                                        }}
                                        onClick={() => {
                                          handleClickCollection(item)
                                        }}
                                    >
                                      {item.attributes.name}
                                    </div>
                                );
                              })}
                            </div>
                        )}
                      </div>

                      <div className="note-area">
                        Take note for current question
                        <br/>
                        <textarea
                            onFocus={() => {
                              if (onFocusRef) {
                                onFocusRef.current = true;
                              }
                            }}
                            onBlur={() => {
                              if (onFocusRef) {
                                onFocusRef.current = false;
                              }
                            }}
                            value={note} onChange={(e) => {
                          changedNoteRef.current = true;
                          setNote(e.target.value);
                        }} className={'form-control'} name="note" id="note"
                            cols="30" rows="3"></textarea>
                        {!!noteId && (
                            <div className="note-actions">
                              <Button danger block onClick={deleteNote}>Delete
                                note</Button>
                              <Button type={'primary'} disabled={!note} block onClick={saveNote}>Update note</Button>
                            </div>
                        )}

                        {!noteId && (
                            <div className="note-actions">
                              <Button type={'primary'} disabled={!note} block onClick={saveNote}>Add note</Button>
                            </div>
                        )}
                      </div>
                      <br/>
                      {/*<Button*/}
                      {/*    block*/}
                      {/*    type={'primary'}*/}
                      {/*    onClick={saveThisTest}*/}
                      {/*>*/}
                      {/*  Finish this test</Button>*/}

                      {!isShowResults && (
                          <Fragment>
                            {/*<Space className={'question-navigator'}>*/}
                            {/*  <Button block danger className={'btn'}>Back question</Button>*/}
                            {/*  <Button block danger className={'btn'}>Flag to review</Button>*/}
                            {/*  <Button block danger className={'btn'}>Next question</Button>*/}
                            {/*</Space>*/}
                            <Button
                                block
                                type={'primary'}
                                onClick={showResults}
                            >
                              Finish this test</Button>
                          </Fragment>
                      )}

                      {!!showHotKeysGuide && (
                          <div className={'shortKeys'}>
                            <br/>
                            Hot keys:
                            <br/>
                            Next question: ArrowRight
                            <br/>
                            Back question: ArrowLeft
                            <br/>
                            Mark to review: ArrowUp or ArrowDown
                            <br/>
                            Choose option: Press number 1 to 6
                          </div>
                      )}

                      {isShowResults && (
                          <div className={'explanation'}>
                            <br/><br/>
                            <div dangerouslySetInnerHTML={{
                              __html: convertToHtmlToOpenLinkInNewTab(questions[exam.current].attributes.answerExplanation),
                            }}></div>
                          </div>
                      )}

                    </div>
                  </div>
                </div>
            )}
          </div>

          {!!exam && (
              <div className={`float-area ${isShowResults && 'show-results'}`}>
                <div className="float-wrapper">

                  <div className="first-row">
                    <div
                        style={{
                          textAlign: 'right',
                          paddingRight: '10px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-end',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setShowPaging(!showPaging);
                        }}>
                      {showPaging ? 'Hide pagination' : 'Show pagination'}
                    </div>
                  </div>

                  {!!showPaging && (
                      <div className={'second-row'}>
                        {!!isShowResults && (
                            <center>
                              <div className="wrapper">
                                <h2>Results: {questions.filter(item => {
                                  return item.selectedIds.sort().toString() === item.validIds.sort().toString();
                                }).length}/{questions.length}</h2>
                              </div>
                            </center>
                        )}
                        <div className="question-boxes">
                          {!!exam && !!questions && questions.map((item, index) => {
                            return (
                                <div key={item.id} onClick={() => {
                                  changeQuestion(index);
                                }} className={`box ${index === exam.current &&
                                'current'} ${item.selectedIds && item.selectedIds.length &&
                                'answered'} ${!!item.flag && 'flag'} ${isShowResults && (item.selectedIds.sort().toString() === item.validIds.sort().toString()) && 'correct'}`}>{index + 1}</div>
                            );
                          })}
                        </div>
                      </div>
                  )}

                  {/*<div className="question-actions">*/}
                  {/*  <Button*/}
                  {/*      onClick={previousQuestion}*/}
                  {/*      disabled={exam.current === 0}>Previous question (Left)</Button>*/}
                  {/*  <Button*/}
                  {/*      onClick={markToReview}*/}
                  {/*  >Mark to review later (Up)</Button>*/}
                  {/*  <Button*/}
                  {/*      onClick={nextQuestion}*/}
                  {/*      disabled={exam.current === questions.length - 1}>Next question (Right)</Button>*/}
                  {/*</div>*/}
                </div>
              </div>
          )}

        </div>
      </div>
  );
}

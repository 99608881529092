import {useEffect, useState} from 'react';
import {getAxios} from '../services/api';
import {useHistory} from 'react-router-dom';
import {Button, message, Space} from 'antd';
import qs from 'qs';
import {randomizeArrayOrder} from '../helper';
import {Helmet} from 'react-helmet';

export default function ContactPage() {

  return (
      <div>
        <Helmet>
          <title>Contact us - IT Exam</title>
        </Helmet>

        <iframe className="airtable-embed" src="https://airtable.com/embed/appg5P7LeQ8I9MBYq/shrSa0ya8rwv2CdQI?backgroundColor=blueDusty" frameBorder="0" width="100%" height="100vh"></iframe>
      </div>
  );
}

import {useEffect, useState} from 'react';
import {getAxios} from '../services/api';
import {useHistory} from 'react-router-dom';
import {Button, Checkbox, Col, Form, Input, message, Row, Select, Tree, TreeSelect} from 'antd';
import qs from 'qs';
import {randomizeArrayOrder} from '../helper';
import {Helmet} from 'react-helmet';

const {Option} = Select;

const layout = {
  labelCol: {span: 24},
  wrapperCol: {span: 24},
};

export default function Search() {
  const history = useHistory();
  const [questions, setQuestions] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [isSearched, setIsSearched] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    console.log({
      values,
      checkedKeys,
    });

    if (!checkedKeys || !checkedKeys.length) {
      message.error('Please select at least one course to search');
      return;
    }

    let ids = []
    try {
      ids = checkedKeys.filter(item => String(item).indexOf('-') >= 0).map(item => item.split('-')[1]);
    } catch (e) {
      console.log(e);
    }
    const orConditions = [];

    if (values.searchLocations.includes('title')) {
      orConditions.push({
        content: {
          $contains: values.keyword,
        },
      })
    }

    if (values.searchLocations.includes('correctAnswer')) {
      orConditions.push({
        options: {
          isValid: {
            $eq: true
          },
          content: {
            $contains: values.keyword,
          }
        }
      })
    }

    if (values.searchLocations.includes('inCorrectAnswer')) {
      orConditions.push({
        options: {
          isValid: {
            $eq: false
          },
          content: {
            $contains: values.keyword,
          }
        }
      })
    }

    // if (values.searchLocations.includes('questionExplanation')) {
    //   orConditions.push({
    //     answerExplanation: {
    //       $contains: values.keyword,
    //     },
    //   })
    // }

    const query = qs.stringify({
      populate: ['options'],
      pagination: {
        pageSize: Number(values.limit) || 1000,
      },
      filters: {
        $and: [
          {
            course: {
              id: {
                $in: ids,
              },
            },
          },
        ],
        $or: orConditions,
      },
    }, {
      encodeValuesOnly: true,
    });

    (async () => {
      const res = await getAxios().get(`/api/questions?${query}`);
      setQuestions(randomizeArrayOrder(res.data.data));
      setIsSearched(true)
    })();
  };

  const onSelect = (selectedKeysValue, info) => {
    const target = info.node.key;

    setSelectedKeys([]);

    if (!checkedKeys.includes(target)) {
      // If is number
      if (!isNaN(target)) {
        setCheckedKeys([
          ...checkedKeys,
          target,
          ...info.node.children.map(node => node.key)
        ]);
      } else {
        setCheckedKeys([
          ...checkedKeys,
          target
        ])
      }
    } else {
      // If is number
      if (!isNaN(target)) {
        const allIds = [
          target,
          ...info.node.children.map(node => node.key)
        ];
        const newIds = checkedKeys.filter(item => {
          return !allIds.includes(item);
        });
        setCheckedKeys([
          ...newIds
        ])
      } else {
        const newIds = checkedKeys.filter(item => {
          return item !== target;
        });
        setCheckedKeys([
          ...newIds
        ])
      }
    }
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  useEffect(() => {
    const query = qs.stringify({
      populate: ['courses'],
    }, {
      encodeValuesOnly: true,
    });

    (async () => {
      const res = await getAxios().get(`/api/exams?${query}`);
      const items = res.data.data.map(exam => {
        return {
          title: exam.attributes.name,
          value: exam.id,
          key: exam.id,
          children: exam.attributes.courses.data.map(course => {
            return {
              title: `Test ${course.id}`,
              // title: course.attributes.name,
              value: `${exam.id}-${course.id}`,
              key: `${exam.id}-${course.id}`,
            }
          })
        }
      })

      setTreeData(items);
    })();
  }, []);

  function startTestNow() {
    let questionNumber = prompt('How many questions of exam?', 10);
    if (!questionNumber) {
      return;
    }

    questionNumber = Number(questionNumber) || 65;

    const examQuestions = questions.slice(0, questionNumber);

    const items = examQuestions.map(item => {
      return {
        ...item,
        validIds: item.attributes.options.filter(item => item.isValid).map(item => item.id),
        selectedIds: [],
      };
    });

    const DEFAULT_EXAM = {
      current: 0,
      validIds: [],
      selectedIds: [],
    };

    localStorage.setItem('exam', JSON.stringify(DEFAULT_EXAM));
    localStorage.setItem('questions', JSON.stringify(items));
    localStorage.removeItem('historyMode');
    history.push('/current-exam');
  }

  return (
      <div>
        <Helmet>
          <title>Questions - IT Exam</title>
        </Helmet>

        <Form
            initialValues={{
              searchLocations: ['correctAnswer', 'title'],
              keyword: '',
            }}
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{maxWidth: 600, margin: '0 auto'}}
        >
          <Form.Item name="keyword" label="Keyword" rules={[{required: false}]}>
            <Input/>
          </Form.Item>
          <Form.Item name="searchLocations" label="Search locations of Keyword">
            <Checkbox.Group>
              <Row>
                <Col span={24}>
                  <Checkbox value="title" style={{ lineHeight: '32px' }}>Question title</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="correctAnswer" style={{ lineHeight: '32px' }}>Correct answer</Checkbox>
                </Col>
                <Col span={24}>
                  <Checkbox value="inCorrectAnswer" style={{ lineHeight: '32px' }}>In-correct answer</Checkbox>
                </Col>
                {/*<Col span={24}>*/}
                {/*  <Checkbox value="questionExplanation" style={{ lineHeight: '32px' }}>Question explanation</Checkbox>*/}
                {/*</Col>*/}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label="Courses (required)" rules={[{required: false}]} className={'wrapper-tree'}>
            <Tree
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                onSelect={onSelect}
                checkable={true}
                treeData={treeData}
            />
          </Form.Item>
          <Form.Item>
            <Button disabled={!checkedKeys || !checkedKeys.length} type="default" htmlType="submit" block>
              Search questions
            </Button>
          </Form.Item>
        </Form>

        <br/>

        {!!isSearched && (
            <div className={'results'}>
              <div className="overview">
                There are {questions.length} questions matched your conditions.
                <br/><br/>
                <Button type={'primary'} onClick={startTestNow} block>Generate exam</Button>
                <br/><br/>
              </div>
            </div>
        )}
      </div>
  );
}

import {Fragment, useEffect, useState} from 'react';
import {getAxios} from '../services/api';
import {useHistory} from 'react-router-dom';
import {Button, Checkbox, Col, Form, Input, Row, Tree} from 'antd';
import qs from 'qs';
import {randomizeArrayOrder} from '../helper';
import {Helmet} from 'react-helmet';

const QUESTIONS = require('../questions.json');

const layout = {
  labelCol: {span: 24},
  wrapperCol: {span: 24},
};

export default function CoursesPage() {
  const [courses, setCourses] = useState([]);
  const [results, setResults] = useState([]);

  const [treeData, setTreeData] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [isSearched, setIsSearched] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    await searchCourses(values);
  };

  const onSelect = (selectedKeysValue, info) => {
    const target = info.node.key;

    setSelectedKeys([]);

    if (!checkedKeys.includes(target)) {
      // If is number
      if (!isNaN(target)) {
        setCheckedKeys([
          ...checkedKeys,
          target,
          ...info.node.children.map(node => node.key)
        ]);
      } else {
        setCheckedKeys([
          ...checkedKeys,
          target
        ])
      }
    } else {
      // If is number
      if (!isNaN(target)) {
        const allIds = [
          target,
          ...info.node.children.map(node => node.key)
        ];
        const newIds = checkedKeys.filter(item => {
          return !allIds.includes(item);
        });
        setCheckedKeys([
          ...newIds
        ])
      } else {
        const newIds = checkedKeys.filter(item => {
          return item !== target;
        });
        setCheckedKeys([
          ...newIds
        ])
      }
    }
  };

  const onCheck = (checkedKeysValue) => {
    setCheckedKeys(checkedKeysValue);
  };

  useEffect(() => {
    const query = qs.stringify({
      populate: [],
    }, {
      encodeValuesOnly: true,
    });

    (async () => {
      const res = await getAxios().get(`/api/exams?${query}`);
      const items = res.data.data.map(exam => {
        return {
          title: exam.attributes.name,
          value: exam.id,
          key: exam.id,
          children: []
        }
      })

      setTreeData(items);
    })();
  }, []);

  const history = useHistory();

  async function searchCourses(values) {
    const query = qs.stringify({
      populate: [],
      pagination: {
        pageSize: Number(values.limit) || 1000,
      },
      filters: {
        $and: [
          {
            exam: {
              id: {
                $in: checkedKeys,
              },
            },
          },
        ],
      },
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/courses?${query}`);
    setCourses(res.data.data);
    setIsSearched(true)
  }

  async function insertData() {
    console.log('DEBUG QUESTIONS', QUESTIONS);
    for (let i = 0; i < QUESTIONS.length; i++) {
      const item = QUESTIONS[i];
      try {
        await getAxios().post('/api/questions', {
          data: {
            "content": item.content,
            "answerExplanation": item.answerExplanation,
            "course": {
              "connect": [
                {id: item.course}
              ]
            },
            "options": item.options
          }
        });
      } catch (e) {

      }
    }
  }

  async function doThisTestNow(course) {
    const query = qs.stringify({
      populate: ['options'],
      pagination: {
        pageSize: 1000,
      },
      filters: {
        $and: [
          {
            course: {
              id: {
                $in: course.id,
              },
            },
          },
        ],
      },
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/questions?${query}`);
    const items = randomizeArrayOrder(res.data.data).map(item => {
      return {
        ...item,
        validIds: item.attributes.options.filter(item => item.isValid).map(item => item.id),
        selectedIds: [],
      };
    });

    const DEFAULT_EXAM = {
      current: 0,
      validIds: [],
      selectedIds: [],
    };

    localStorage.setItem('exam', JSON.stringify(DEFAULT_EXAM));
    localStorage.setItem('questions', JSON.stringify(items));
    localStorage.removeItem('historyMode');
    history.push('/current-exam');
  }

  return (
      <div>

        <Helmet>
          <title>Courses - IT Exam</title>
        </Helmet>

        <Form
            {...layout}
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            style={{maxWidth: 600, margin: '0 auto'}}
        >
          <Form.Item label="Exams" rules={[{required: false}]} className={'tree-exams'}>
            <Tree
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                onSelect={onSelect}
                checkable={true}
                treeData={treeData}
            />
          </Form.Item>
          <Form.Item>
            <Button type="default" htmlType="submit" block>
              Search courses
            </Button>
          </Form.Item>
        </Form>
        <br/>

        {!!isSearched && (
            <Fragment>
              <h3>Search results</h3>
              <p>Note: Please be aware that this tool is designed to enhance your practice. To support the author, kindly consider purchasing the course. We do not own the content of the courses listed below.</p>
              <table className={'table table-sm table-striped'}>
                <thead className={'thead-dark'}>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Link to buy</th>
                  <th style={{
                    textAlign: 'right',
                  }}>Action
                  </th>
                </tr>
                </thead>
                <tbody>
                {courses.map((course, index) => {
                  return (
                      <tr key={course.id}>
                        <td>{index + 1}</td>
                        <td>{course.attributes.name}</td>
                        <td style={{
                          maxWidth: '200px'
                        }}>
                          <a className={'oneRow'} href={course.attributes.url} target={'_blank'}>{course.attributes.url}</a>
                        </td>
                        <td style={{
                          textAlign: 'right',
                        }}>
                          <Button type={'default'}
                                  onClick={() => {
                                    doThisTestNow(course);
                                  }}
                          >
                            <a>Run test</a>
                          </Button>
                        </td>
                      </tr>
                  );
                })}
                <tr></tr>
                </tbody>
              </table>
            </Fragment>
        )}

        <button
          onClick={insertData}
        >Insert data</button>
      </div>
  );
}

import {useEffect, useState} from 'react';
import {getAxios} from '../services/api';
import {useHistory} from 'react-router-dom';
import {Button, message, Space} from 'antd';
import qs from 'qs';
import {Helmet} from 'react-helmet';

export default function CollectionsPage() {
  const [items, setItems] = useState([]);
  const [results, setResults] = useState([]);
  const history = useHistory();

  async function loadItems() {
    const userId = JSON.parse(localStorage.getItem('user')).id;

    const query = qs.stringify({
      populate: [
        'questions',
        'questions.options',
      ],
      sort: 'createdAt:desc',
      filters: {
        owner: {
          id: {
            $eq: userId,
          },
        },
      },
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/collections?${query}`);
    setItems(res.data.data);
  }

  useEffect(() => {
    (async () => {
      await loadItems();
    })();
  }, []);

  async function runTheTestNow(collection) {
    let questionNumber = prompt('How many questions of exam?', 10);
    if (!questionNumber) {
      return;
    }

    questionNumber = Number(questionNumber) || 65;

    const examQuestions = collection.attributes.questions.data.slice(0, questionNumber);

    const items = examQuestions.map(item => {
      return {
        ...item,
        validIds: item.attributes.options.filter(item => item.isValid).map(item => item.id),
        selectedIds: [],
      };
    });

    const DEFAULT_EXAM = {
      current: 0,
      validIds: [],
      selectedIds: [],
    };

    localStorage.setItem('exam', JSON.stringify(DEFAULT_EXAM));
    localStorage.setItem('questions', JSON.stringify(items));
    localStorage.removeItem('historyMode');
    history.push('/current-exam');
  }

  async function handleAddNew() {
    const name = await prompt('Enter your collection name?');
    if (name) {
      try {
        await getAxios().post('/api/collections', {
          data: {
            name: name,
            owner: JSON.parse(localStorage.getItem('user')).id,
          },
        });

        message.success('Added new collection successfully');
        loadItems();
      } catch (e) {
        message.error('Failed to add new collection');
      }
    }
  }

  return (
      <div>

        <Helmet>
          <title>My collections - IT Exam</title>
        </Helmet>

        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
          <h3>My collections</h3>
          <Button onClick={handleAddNew}>Add new</Button>
        </div>
        <table className={'table table-sm table-striped'}>
          <thead className={'thead-dark'}>
          <tr>
            <th>No</th>
            <th>Name</th>
            <th>Number of questions</th>
            <th style={{
              textAlign: 'right',
            }}>Action
            </th>
          </tr>
          </thead>
          <tbody>
          {items.map((item, index) => {
            return (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item.attributes.name}</td>
                  <td>{item.attributes.questions?.data?.length}</td>
                  <td style={{
                    textAlign: 'right',
                  }}>
                    <Space>
                      <Button type={'default'}
                              onClick={() => {
                                runTheTestNow(item);
                              }}
                      >
                        <a>Run the test now</a>
                      </Button>
                    </Space>
                  </td>
                </tr>
            );
          })}
          <tr></tr>
          </tbody>
        </table>
      </div>
  );
}

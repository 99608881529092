import {useEffect, useState} from 'react';
import {getAxios} from '../services/api';
import {useHistory} from 'react-router-dom';
import {Button, message, Space} from 'antd';
import qs from 'qs';
import _ from 'lodash';
import {getInnerTextOfHtml, randomizeArrayOrder} from '../helper';
import {Helmet} from 'react-helmet';

export default function MyFalseResponsesPage() {
  const [falseItems, setFalseItems] = useState([]);
  const history = useHistory();

  async function loadItems() {
    const userId = JSON.parse(localStorage.getItem('user')).id;

    const query = qs.stringify({
      populate: ['question'],
      filters: {
        user: {
          id: {
            $eq: userId,
          },
        },
      },
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/false-responses?${query}`);

    const groupedItems = {};
    res.data.data.forEach(item => {
      const id = item.id;
      const content = item.attributes.question.data.attributes.content;

      const existed = groupedItems[item.attributes.question.data.id];
      if (existed) {
        existed.counter++;
        existed.rowIds.push(id);
      } else {
        groupedItems[item.attributes.question.data.id] = {
          id: item.attributes.question.data.id,
          content,
          rowIds: [id],
          counter: 1,
        }
      }
    });

    const arrayItems = Object.keys(groupedItems).map(key => {
      return groupedItems[key]
    });

    setFalseItems(_.orderBy(arrayItems, 'counter', 'desc').slice(0, 20));
  }

  useEffect(() => {
    (async () => {
      await loadItems();
    })();
  }, []);

  async function viewInTestingMode(itemId) {
    const ids = falseItems.map(item => {
      try {
        return item.id;
      } catch (e) {
        return null;
      }
    }).filter(item => !!item);

    const query = qs.stringify({
      populate: ['options'],
      pagination: {
        pageSize: 1000,
      },
      filters: {
        id: {
          $in: ids,
        },
      },
    }, {
      encodeValuesOnly: true,
    });

    const res = await getAxios().get(`/api/questions?${query}`);
    const items = randomizeArrayOrder(res.data.data).map(item => {
      return {
        ...item,
        validIds: item.attributes.options.filter(item => item.isValid).map(item => item.id),
        selectedIds: [],
      };
    });

    let current = 0;
    items.forEach((item, index) => {
      if (item.id === itemId) {
        current = index;
      }
    })

    const DEFAULT_EXAM = {
      current,
      validIds: [],
      selectedIds: [],
    };

    localStorage.setItem('exam', JSON.stringify(DEFAULT_EXAM));
    localStorage.setItem('questions', JSON.stringify(items));
    localStorage.removeItem('historyMode');
    history.push('/current-exam');
  }

  async function deleteThisQuestion(item) {
    for (let i = 0; i < item.rowIds.length; i++) {
      await getAxios().delete(`/api/false-responses/${item.rowIds[i]}`);
    }
    message.success('Deleted item successfully');
    await loadItems();
  }

  return (
      <div>
        <Helmet>
          <title>My wrong answers - IT Exam</title>
        </Helmet>

        <h3>Wrong answers you made</h3>
        <table className={'table table-sm table-striped'}>
          <thead className={'thead-dark'}>
          <tr>
            <th>No</th>
            <th>Content</th>
            <th>Counter</th>
            <th style={{
              textAlign: 'right',
            }}>Actions
            </th>
          </tr>
          </thead>
          <tbody>
          {falseItems.map((item, index) => {
            return (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>
                    <div className={'twoRows'} dangerouslySetInnerHTML={{
                      __html: getInnerTextOfHtml(item.content)
                    }}></div>
                  </td>
                  <td>{item.counter}</td>
                  <td style={{
                    textAlign: 'right',
                  }}>
                    <Space>
                      <Button type={'default'}
                              onClick={() => {
                                viewInTestingMode(item.id);
                              }}
                      >
                        <a>View</a>
                      </Button>
                      {/*<Button danger*/}
                      {/*        onClick={() => {*/}
                      {/*          deleteThisQuestion(item);*/}
                      {/*        }}*/}
                      {/*>*/}
                      {/*  <a>Delete</a>*/}
                      {/*</Button>*/}
                    </Space>
                  </td>
                </tr>
            );
          })}
          <tr></tr>
          </tbody>
        </table>
      </div>
  );
}

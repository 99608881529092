import React, {Fragment, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai';

export function AuthLayout({children, authRequired = true}) {
  const [valid, setValid] = useState(false);
  const [isShowExam, setIsShowExam] = useState(false);

  const [showPopupMenu, setShowPopupMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [user, setUser] = useState(null);

  let location = useLocation();

  useEffect(() => {
    setShowPopupMenu(false)
  }, [location]);
  //
  // useEffect(() => {
  //   if (localStorage.getItem('exam')) {
  //     setIsShowExam(true);
  //   }
  // }, []);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setValid(true);
      try {
        setUser(JSON.parse(localStorage.getItem('user')));
      } catch (e) {}
    } else {
      if (authRequired) {
        localStorage.setItem('redirectUrl', window.location.href);
        window.location.href = '/auth/init';
      } else {
        setValid(true);
      }
    }
  }, []);

  useEffect(() => {
    setIsShowExam(!!localStorage.getItem('exam'));
  }, [location.pathname]);

  if (!valid) {
    return (
        <div>
          <center>Loading data...</center>
        </div>
    );
  }

  function renderMenuItems() {
    return (
        <nav className={`nav-items`}>
          {!!user && (
            <Fragment>
              {/*<Link to={'/'} className={'nav-item'}>*/}
              {/*  Home*/}
              {/*</Link>*/}
              <Link to={'/questions'} className={'nav-item'}>
                Questions
              </Link>
              {/*<Link to={'/courses'} className={'nav-item'}>Courses</Link>*/}
              <Link to={'/my-collections'} className={'nav-item'}>
                My collections
              </Link>

              <Link to={'/shared-collections'} className={'nav-item'}>
                Shared collections
              </Link>
              <Link to={'/my-wrong-answers'} className={'nav-item'}>
                Wrong answers
              </Link>
              {/*<Link to={'/top-wrong-answers'} className={'nav-item'}>*/}
              {/*  Top wrong answers*/}
              {/*</Link>*/}
              <Link to={'/histories'} className={'nav-item'}>
                Histories
              </Link>
              <Link to={'/notes'} className={'nav-item'}>
                Notes
              </Link>
              {isShowExam && (
                  <Link to={'/current-exam'} className={'nav-item'}>
                    Current exam
                  </Link>
              )}
              {/*<Link to={'/contact-us'} className={'nav-item'}>*/}
              {/*  Contact us*/}
              {/*</Link>*/}
              <Link to={'/auth/logout'} className={'nav-item'}>
                Logout ({user?.username})
              </Link>
            </Fragment>
          )}
          {!user && (
              <Link to={'/auth/init'} className={'nav-item'}>
                Login
              </Link>
          )}
        </nav>
    )
  }

  return (
      <div className={'authenticatedWrapper'}>
        <div className="outer-header">
          <div className="wrapper">
            <div className="header">
              <div className="logo">
                <Link to={'/'}>
                  <h1>
                    <img height={'35px'} src="/white-logo.svg" alt="IT Exam"/>
                  </h1>
                </Link>
              </div>

              <div className="right">
                <div className="mobile-icon" onClick={() => {
                  setShowPopupMenu(!showPopupMenu)
                }}>
                  {!!showPopupMenu && (<AiOutlineClose size={25} color={'white'} />)}
                  {!showPopupMenu && (<AiOutlineMenu size={25} color={'white'} />)}
                </div>
                <div className="desktop-menu">
                  {renderMenuItems()}
                </div>
              </div>
            </div>
          </div>

          {!!showPopupMenu && (
              <div className={'wrapper'}>
                <div className={`menu-popup`}>
                  {renderMenuItems()}
                </div>
              </div>
          )}
        </div>

        <div className="pageContent">
          <div className="wrapper">
            {children}
          </div>
        </div>

        {/*<footer className="mastfoot mt-auto">*/}
        {/*  <div className="inner">*/}
        {/*    <p>Cover template for <a*/}
        {/*        href="https://getbootstrap.com/">Bootstrap</a>, by <a*/}
        {/*        href="https://twitter.com/mdo">@mdo</a>.</p>*/}
        {/*  </div>*/}
        {/*</footer>*/}
      </div>
  );
}
